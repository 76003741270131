import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Logs from "../components/Logs";
import { getTasksLogs } from "../redux/reducers/taskReducer";
import LoaderComponent from "../components/Loader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";


const Activitylogs = () => {
  let location = useLocation();
  const dispatch=useDispatch()
  let params = new URLSearchParams(location.search);
  let taskId = params.get("id");
  const taskLogs = useSelector((state) => state.tasks.taskLogs);
  const [loader,setLoader]=useState(true)

useEffect(()=>{
  dispatch(getTasksLogs(parseInt(taskId))).then(res=>setLoader(false))
},[taskId])

  return (
    <>
    {
      loader?<LoaderComponent/>:<Logs taskLogs={taskLogs} />
    } 
    </>
  );
};

export default Activitylogs;
