import React from "react";
import { useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Card,
} from "react-bootstrap";
import { ImAttachment } from "react-icons/im";
import {
  FaTrash,
  FaUser,
  FaFileSignature,
  FaCommentDots,
  FaPlus,
} from "react-icons/fa";
import { MdChecklistRtl, MdApproval, MdPriorityHigh } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { VscCloseAll } from "react-icons/vsc";
import { AiOutlineFileText } from "react-icons/ai";
import Avatar from "./Avatar";
import "./Logs.scss";
import moment from "moment";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

const Logs = ({ taskLogs }) => {
  console.log("taskLogs",taskLogs)
  TimeAgo.addLocale(en);
  const timeAgo = new TimeAgo("en-US");

  const fetchActivityLog = (log) => {
    console.log(log, ":::::::>>>>>>>>>>");

    let date = new Date(log.created_at);

    const overlayer = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 150, hide: 400 }}
        overlay={
          <Tooltip id="tooltip-task-name">
            {moment(log.created_at).format("ddd, MMM DD, YYYY, h:mm A")}
          </Tooltip>
        }
      >
        <span className="time">{timeAgo.format(date)}</span>
      </OverlayTrigger>
    );

    const avatar = (
      <Avatar
        image={log?.avatar}
        initials={`${log?.context?.firstname ? log.context.firstname.substring(0, 1).toUpperCase() : 'N'}${log?.context?.lastname ? log.context.lastname.substring(0, 1).toUpperCase() : 'A'}`}
        color="--br-danger"
      />
    );

    if (log.operation == "ATTACHMENT-CREATE") {
      return (
        <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
          <div className="icon">
            <ImAttachment className="attachments" />
          </div>
          {avatar}
          <div className="d-flex align-items-center justify-content-between content">
            <h5 className="m-0">
              <span>
                {log?.context?.firstname} {log?.context?.lastname}{" "}
              </span>
              {log?.context?.operation} a {log?.context?.type}
              <span>{log?.context?.filename}</span>
            </h5>
            {overlayer}
          </div>
        </div>
      );
    }
    if (log.operation == "ATTACHMENT-DELETE") {
      return (
        <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
          <div className="icon">
            <FaTrash className="delete" />
          </div>
          {avatar}
          <div className="d-flex align-items-center justify-content-between content">
            <h5 className="m-0">
              <span>
                {log?.context?.firstname} {log?.context?.lastname}{" "}
              </span>
              {log?.context?.operation} an {log?.context?.type}
              <span>{log?.context?.filename}</span>
            </h5>
            {overlayer}{" "}
          </div>
        </div>
      );
    }
    if (log.operation == "CHECKLIST-CREATE") {
      return (
        <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
          <div className="icon">
            <MdChecklistRtl className="checklist" />
          </div>
          {avatar}
          <div className="d-flex align-items-center justify-content-between content">
            <h5 className="m-0">
              <span>
                {log?.context?.firstname} {log?.context?.lastname}{" "}
              </span>
              {log?.context?.operation} a <span>{log?.context?.type}</span>
            </h5>
            <OverlayTrigger
              placement="top"
              delay={{ show: 150, hide: 400 }}
              overlay={
                <Tooltip id="tooltip-task-name">
                  {moment(log.created_at).format("ddd, MMM DD, YYYY, h:mm A")}
                </Tooltip>
              }
            >
              <span className="time">{timeAgo.format(date)}</span>
            </OverlayTrigger>{" "}
          </div>
        </div>
      );
    }
    if (log.operation == "CHECKLIST-UPDATE") {
      return (
        <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
          <div className="icon">
            <FaTrash className="delete" />
          </div>
          {avatar}
          <div className="d-flex align-items-center justify-content-between content">
            <h5 className="m-0">
              <span>
                {log?.context?.firstname} {log?.context?.lastname}{" "}
              </span>
              {log?.context?.operation} a <span>{log?.context?.type}</span>
            </h5>
            {overlayer}{" "}
          </div>
        </div>
      );
    }
    if (log.operation == "CHECKLIST-DELETE") {
      return (
        <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
          <div className="icon">
            <FaTrash className="delete" />
          </div>
          {avatar}
          <div className="d-flex align-items-center justify-content-between content">
            <h5 className="m-0">
              <span>
                {log?.context?.firstname} {log?.context?.lastname}{" "}
              </span>
              {log?.context?.operation} a <span>{log?.context?.type}</span>
            </h5>
            {overlayer}
          </div>
        </div>
      );
    }

    if (log.operation === "CREATE") {
      return (
        <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
          <div className="icon">
            <FaPlus className="create" />
          </div>
          {avatar}
          <div className="d-flex align-items-center justify-content-between content">
            <h5 className="m-0">
              <span className="ms-0">{log?.json.updated_user}</span>
              Created a new task
              <span>{log?.json.name}</span>
            </h5>
            {overlayer}
          </div>
        </div>
      );
    }
    if (log.context.field_name === "force") {
      return (
        <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
          <div className="icon">
            <VscCloseAll className="" />
          </div>
          {avatar}
          <div className="d-flex align-items-center justify-content-between content">
            <h5 className="m-0">
              <span>
                {log?.context?.firstname} {log?.context?.lastname}{" "}
              </span>
              {log?.context?.from}
            </h5>
            {overlayer}
          </div>
        </div>
      );
    }
    if (log?.operation == "COMMENT-CREATE") {
      return (
        <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
          <div className="icon">
            <FaCommentDots className="comment m-0" />
          </div>
          {avatar}
          <div className="d-flex align-items-center justify-content-between content">
            <h5 className="m-0">
              <span>
                {log?.context?.firstname} {log?.context?.lastname}{" "}
              </span>
              {log?.context?.operation} a <span>{log?.context?.type}</span>
            </h5>
            {overlayer}
          </div>
        </div>
      );
    }
    if (log?.operation == "COMMENT-DELETE") {
      return (
        <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
          <div className="icon">
            <FaTrash className="delete" />
          </div>
          {avatar}
          <div className="d-flex align-items-center justify-content-between content">
            <h5 className="m-0">
              <span>
                {log?.context?.firstname} {log?.context?.lastname}{" "}
              </span>
              {log?.context?.operation} a <span>{log?.context?.type}</span>
            </h5>
            {overlayer}
          </div>
        </div>
      );
    }
    if (log?.context?.field_name == "self approve") {
      return (
        <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
          <div className="icon">
            <MdApproval className="" />
          </div>
          {avatar}
          <div className="d-flex align-items-center justify-content-between content">
            {/* <h5 className="m-0">{log?.context?.name + log?.context?.from}</h5> */}
            <h5 className="m-0">
              <span>
                {log?.context?.firstname} {log?.context?.lastname}
              </span>
              {log?.context?.from}
            </h5>
            {overlayer}{" "}
          </div>
        </div>
      );
    }
    if (log?.operation === "UPDATE") {
      if (log.context.field_name == "assignee") {
        return (
          <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
            <div className="icon">
              <FaPlus className="create" />
            </div>
            {avatar}
            <div className="d-flex align-items-center justify-content-between content">
              {/* <h5 className="m-0">{log.context.to}</h5> */}
              <h5 className="m-0">
                <span>
                  {log?.context?.firstname} {log?.context?.lastname}{" "}
                </span>
                {log?.context?.operation} a{" "}
                <span>
                  {log?.context?.addeduser}
                  {log?.context?.removeduser}
                </span>
              </h5>
              {overlayer}
            </div>
          </div>
        );
      }

      if (log?.context.field_name === "internal_status") {
        return (
          <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
            <div className="icon">
              <AiOutlineFileText />
            </div>
            {avatar}
            <div className="d-flex align-items-center justify-content-between content">
              <h5 className="m-0">
                <span>
                  {log?.context?.firstname} {log?.context?.lastname}{" "}
                </span>{" "}
                Updated{" "}
                {log.context.field_name === "internal_status" ? "Status" : ""}{" "}
                from <span>{log.context.from}</span> to{" "}
                <span>{log.context.to}</span>
              </h5>
              {overlayer}
            </div>
          </div>
        );
      }

      if (log?.context.field_name === "internal_priority") {
        return (
          <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
            <div className="icon">
              <MdPriorityHigh />
            </div>
            {avatar}
            <div className="d-flex align-items-center justify-content-between content">
              <h5 className="m-0">
                <span>
                  {log?.context?.firstname} {log?.context?.lastname}{" "}
                </span>{" "}
                Updated{" "}
                {log.context.field_name === "internal_priority"
                  ? "Priority"
                  : ""}{" "}
                from <span>{log.context.from}</span> to{" "}
                <span>{log.context.to}</span>
              </h5>
              {overlayer}
            </div>
          </div>
        );
      }

      if (log.context.field_name === "start_date") {
        return (
          <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
            <div className="icon">
              <SlCalender />
            </div>
            {avatar}
            <div className="d-flex align-items-center justify-content-between content">
              <h5 className="m-0">
                <span>
                  {log?.context?.firstname} {log?.context?.lastname}{" "}
                </span>{" "}
                Updated{" "}
                {log.context.field_name === "start_date" ? "Start Date" : ""}{" "}
                <span> from</span>
                {log.context.field_name === "start_date" &&
                  moment(log.context.from, "MM/DD/YYYY").format(
                    "ddd, MMM DD, YYYY, h:mm A"
                  )}
                <span>to</span>
                {log.context.field_name === "start_date" &&
                  moment(log.context.to, "MM/DD/YYYY").format(
                    "ddd, MMM DD, YYYY, h:mm A"
                  )}
              </h5>
              {overlayer}
            </div>
          </div>
        );
      }

      if (log.context.field_name === "due_date") {
        return (
          <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
            <div className="icon">
              <SlCalender />
            </div>
            {avatar}
            <div className="d-flex align-items-center justify-content-between content">
              <h5 className="m-0">
                <span>
                  {log?.context?.firstname} {log?.context?.lastname}{" "}
                </span>{" "}
                Updated{" "}
                {log.context.field_name === "due_date" ? "Due Date" : ""}{" "}
                <span>from</span>{" "}
                {log.context.field_name === "due_date" &&
                  moment(log.context.from, "MM/DD/YYYY").format(
                    "ddd, MMM DD, YYYY, h:mm A"
                  )}
                <span>to</span>
                {log.context.field_name === "due_date" &&
                  moment(log.context.to, "MM/DD/YYYY").format(
                    "ddd, MMM DD, YYYY, h:mm A"
                  )}
              </h5>
              {overlayer}
            </div>
          </div>
        );
      }

      if (log.context.field_name === "description") {
        return (
          <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
            <div className="icon">
              <FaFileSignature />
            </div>
            {avatar}
            <div className="d-flex align-items-center justify-content-between content">
              <h5 className="m-0">
                <span>
                  {log?.context?.firstname} {log?.context?.lastname}
                </span>{" "}
                Updated{" "}
                {log.context.field_name === "description" ? "Description" : ""}{" "}
                from{" "}
                <span>
                  {" "}
                  {log.context.field_name === "description" && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          log.context.from.length < 5
                            ? log.context.from.slice(0, 10) + ".."
                            : log.context.from,
                      }}
                    ></p>
                  )}
                </span>
                to
                <span>
                  {log.context.field_name === "description" && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          log.context.from.length < 5
                            ? log.context.from.slice(0, 10) + ".."
                            : log.context.to,
                      }}
                    ></p>
                  )}
                </span>
              </h5>
              {overlayer}
            </div>
          </div>
        );
      }

      // if (
      //   log?.context.field_name === "internal_status" ||
      //   log?.context.field_name === "internal_priority" ||
      //   log.context.field_name === "due_date" ||
      //   log.context.field_name === "start_date" ||
      //   log.context.field_name === "description"
      // ) {
      //   return (
      //     <div className="d-flex align-items-center gap-2 gap-sm-3 mt-3 w-100 lines">
      //       <div className="icon">
      //         <SlCalender />
      //       </div>
      //       <span className="avatar d-flex align-items-center justify-content-center">
      //         SA
      //       </span>
      //       <div className="d-flex align-items-center justify-content-between content">
      //         <h5 className="m-0">
      //           {log.context.name} Updated{" "}
      //           {log.context.field_name === "internal_priority"
      //             ? "Priority"
      //             : log.context.field_name === "internal_status"
      //             ? "Status"
      //             : log.context.field_name === "start_date"
      //             ? "Start Date"
      //             : log.context.field_name === "due_date"
      //             ? "Due Date"
      //             : log.context.field_name === "description"
      //             ? "Description"
      //             : ""}{" "}
      //           from{" "}
      //           <p className="strike d-inline">
      //             {log.context.field_name === "start_date" ||
      //             log.context.field_name === "due_date" ? (
      //               moment(log.context.from, "MM/DD/YYYY").format(
      //                 "ddd, MMM DD, YYYY, h:mm A"
      //               )
      //             ) : log.context.field_name === "description" ? (
      //               <p
      //                 dangerouslySetInnerHTML={{
      //                   __html:
      //                     log.context.from.length < 5
      //                       ? log.context.from.slice(0, 10) + ".."
      //                       : log.context.from,
      //                 }}
      //               ></p>
      //             ) : (
      //               log.context.from
      //             )}
      //           </p>{" "}
      //           to{" "}
      //           {log.context.field_name === "start_date" ||
      //           log.context.field_name === "due_date" ? (
      //             moment(log.context.to, "MM/DD/YYYY").format(
      //               "ddd, MMM DD, YYYY, h:mm A"
      //             )
      //           ) : log.context.field_name === "description" ? (
      //             <span
      //               dangerouslySetInnerHTML={{
      //                 __html:
      //                   log.context.to.length < 5
      //                     ? log.context.to.slice(0, 10) + ".."
      //                     : log.context.to,
      //               }}
      //             />
      //           ) : (
      //             log.context.to
      //           )}
      //         </h5>
      //         {overlayer}{" "}
      //       </div>
      //     </div>
      //   );
      // }
    }
  };

  return (
    <div className="Logs">
      <Container>
        <Row>
          <Col md={12}>
            <div className="Logs-card-body mt-2">
              <div>{taskLogs?.map((item) => fetchActivityLog(item))}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Logs;
